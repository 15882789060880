<template>
  <div>
    <b-card-code title="Add Category" :before-change="validationForm">
      <validation-observer ref="simpleRules">
        <b-form>
          <b-row>
            <!-- cover -->
            <b-col md="4">
              <b-form-group v-b-popover.hover.top="'Upload Cover Here'" label="Cover" label-for="cover">
                <validation-provider #default="{ errors }" name="Cover" rules="required">
                  <b-form-file v-model="ProfilePictureBase64" :state="errors.length > 0 ? false : null"
                    @change="uploadImage" id='ProfilePictureBase64' accept=".jpg, .png" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- icon -->
            <b-col md="4">
              <b-form-group v-b-popover.hover.top="'Upload Icon Here'" label="Icon" label-for="icon">
                <validation-provider #default="{ errors }" name="Icon" rules="required">
                  <b-form-file v-model="ProfilePictureBase642" :state="errors.length > 0 ? false : null"
                    @change="uploadImage2" id='ProfilePictureBase642' accept=".jpg, .png" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- code -->
            <b-col md="4">
              <b-form-group v-b-popover.hover.top="'Please Enter Weight'" label="Weight" label-for="Weight">
                <validation-provider #default="{ errors }" name="weight" rules="required">
                  <b-form-input type="number" :id="'weight'" v-model="data.weight"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- code -->
            <b-col md="6">
              <b-form-group v-b-popover.hover.top="'Please Enter Code'" label="Code" label-for="Code">
                <validation-provider #default="{ errors }" name="code" rules="required">
                  <b-form-input type="number" :id="'code'" v-model="data.code"
                    :state="errors.length > 0 ? false : null" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <!-- active -->
            <b-col md="6">
              <b-row>
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="active" rules="required">

                    <b-form-group label="Category Activation" label-for="active">
                      <validation-provider #default="{ errors }" name="active" rules="required">
                        <b-form-checkbox switch v-model="data.active">
                          <p v-if="data.active">
                            Category Active
                          </p>
                          <p v-else>Category DisActive</p>
                        </b-form-checkbox>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>


                  </validation-provider>

                </b-col>

                <!-- Sell New Option -->
                <b-col md="4">
                  <b-form-group label="Can Sell C2C Products ?" label-for="is_c2c_sell">
                    <validation-provider #default="{ errors }" name="is_c2c_sell" rules="required">
                      <b-form-checkbox switch v-model="data.is_c2c_sell">
                        <p v-if="data.is_c2c_sell">
                          Can Sell C2C Products
                        </p>
                        <p v-else>Can't Sell C2C Products</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Sell Used Option -->
                <b-col md="4">
                  <b-form-group label="Can Sell B2C Products ?" label-for="is_b2c_sell">
                    <validation-provider #default="{ errors }" name="is_b2c_sell" rules="required">
                      <b-form-checkbox switch v-model="data.is_b2c_sell">
                        <p v-if="data.is_b2c_sell">
                          Can Sell B2C Products
                        </p>
                        <p v-else>Can't Sell B2C Products</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>


              </b-row>
            </b-col>

            <b-col sm="12">
              <hr>
              <p class="h4">Translations</p>
              <!-- Row Loop -->
              <b-row v-for="(item, index) in tranLocaleSelect" :key="index">

                <!-- translation language -->
                <b-col md="6">
                  <validation-provider #default="{ errors }" name="language" rules="required">
                    <b-form-group label="Language" label-for="language" :state="errors.length > 0 ? false : null">
                      <v-select :id="'language' + index" v-model="tranLocaleSelect[index]"
                        :state="tranLocaleSelect[index] === null ? false : true"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="localeOptions"
                        :selectable="option => !option.value.includes('select_value')" label="text" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <!-- Name -->
                <b-col md="6">
                  <b-form-group v-b-popover.hover.top="'Please Enter Category Name'" label="Name" label-for="name">
                    <validation-provider #default="{ errors }" name="name" rules="required">
                      <b-form-input :id="'name' + index" v-model="data.translations[index].name"
                        :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Description -->
                <b-col md="12">
                  <b-form-group v-b-popover.hover.top="'Type Your Description Here'" label="Description"
                    label-for="description">
                    <validation-provider #default="{ errors }" name="description" rules="required">
                      <vue-editor :id="'description' + index" v-model="data.translations[index].description"
                        :state="errors.length > 0 ? false : null" rows="2" />

                      </vue-editor>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- Remove Button -->
                <b-col lg="2" md="3" class="mb-50">
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" v-show="!tranLocaleSelect[index].disa"
                    variant="outline-danger" class="mt-0 mt-md-2" @click="removeItem(index)">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Remove Translation</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr>
                </b-col>
              </b-row>
              <!-- add new button -->
              <b-col sm="2">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" block
                  @click="repeateAgain">
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Add New Translation</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr>
              </b-col>
            </b-col>

            <b-col sm="12">
              <hr>
              <p class="h4">Attributes </p>
              <!-- Row Loop -->
              <b-row v-for="(item, index) in data.attributes" :id="'attribute-' + index + 1"
                :key="'attribute-' + index + 1" ref="row">

                <!-- locale -->
                <b-col md="4">
                  <validation-provider #default="{ errors }" name="attribute Name" rules="required">
                    <b-form-group label="attribute Name" label-for="attribute Name">
                      <v-select v-model="selectedAttributes[index]" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="text" value="id" :state="errors.length > 0 ? false : null"
                        :options="attributes.map(attribute => ({ id: attribute.id, text: attribute.name, is_variant: attribute.is_variant }))"
                        @input="updateSortValidation(index)" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-col>

                <b-col md="4" v-if="selectedAttributes[index] && selectedAttributes[index].is_variant">
                  <validation-provider #default="{ errors }" name="sort">
                    <b-form-group label="sort" label-for="sort">
                      <b-form-input type="number" v-model="item.sort" @input="updateSortValidation(index)"
                        :state="sortValidationErrors[index] ? false : null" />
                      <small class="text-danger">{{ sortValidationErrors[index] }}</small>
                    </b-form-group>
                  </validation-provider>

                </b-col>

                <b-col md="12">
                  <b-form-group label="Add Name In Product Name ?" label-for="is_include_name">
                    <validation-provider #default="{ errors }" name="is_include_name">
                      <b-form-checkbox switch v-model="item.is_include_name" class="mt-1">
                        <p v-if="item.is_include_name">
                          Add Attribute Name In Product Name
                        </p>
                        <p v-else>Remove Attribute Name From Product Name</p>
                      </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>


                <!-- Remove Button -->
                <b-col md="4" class="mb-50">
                  <b-button v-ripple.400="'rgba(234, 84, 85, 0.15)'" v-show="index >= 1" variant="outline-danger"
                    class="mt-0 mt-md-2" @click="removeAttribute(index)">
                    <feather-icon icon="XIcon" class="mr-25" />
                    <span>Remove Attribute</span>
                  </b-button>
                </b-col>
                <b-col cols="12">
                  <hr />
                </b-col>
              </b-row>

              <!-- add new button -->
              <b-col cols="12">
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary" @click="addAttribute">
                  <feather-icon icon="PlusIcon" class="mr-25" />
                  <span>Add Attribute</span>
                </b-button>
              </b-col>
              <b-col cols="12">
                <hr />
              </b-col>
            </b-col>



            <!-- submit button -->
            <b-col>
              <b-button size="lg" variant="primary" type="submit" @click.prevent="validationForm">
                Submit
              </b-button>
            </b-col>


          </b-row>
        </b-form>
      </validation-observer>
    </b-card-code>
    <!-- error handelr -->
    <b-alert v-height-fade.appear fade :show="showDismissibleAlert" @dismissed="showDismissibleAlert = false"
      variant="danger">
      <h4 class="alert-heading">
        Alert
      </h4>
      <div class="alert-body">
        <ul v-for="(values, index) in errors_back" :key="index">
          <li v-for="(value, valIndex) in values" :key="valIndex">{{ value }}</li>
        </ul>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { VueEditor } from 'vue2-editor'
import BCardCode from '@core/components/b-card-code'
import { heightTransition } from '@core/mixins/ui/transition'
import { required } from '@validations'
export default {
  components: {
    VueEditor,
    BCardCode,
  },
  mixins: [heightTransition],
  data() {
    return {
      sortValidationErrors: [],
      attributes: [],
      ProfilePictureBase64: [],
      ProfilePictureBase642: [],
      activeSelect: null,
      selectedAttributes: [],
     
      data: {
        Weight: null,
        icon: [],
        cover: [],
        active:false,
        is_c2c_sell:false,
        is_b2c_sell:false,
        translations: [
          {
            locale: '',
            name: '',
            description: '',
          },
        ],
        attributes: [{
          id: null,
          sort: null,
          is_include_name: false,

        }],
        nextTodoId: 5,
      },
      tranLocaleSelect: [
        {
          value: 'ar',
          text: 'Arabic',
          disa: true,
        },
      ],
      localeOptions: [
        {
          value: 'en',
          text: 'English',
        },
        {
          value: 'ar',
          text: 'Arabic',
        },
      ],
      errors_back: '',
      showDismissibleAlert: false,
      required,
    }
  },
  watch: {
    selectedAttributes: {
      deep: true,
      handler(newSelectedAttributes, oldSelectedAttributes) {
        // console.log(this.selectedAttributes[0])
        // console.log(this.data.attributes)
        // Loop through the new selectedAttributes array
        newSelectedAttributes.forEach((selectedAttribute, index) => {
          // Update the corresponding ID in data.attributes[index].id
          this.$set(this.data.attributes, index, { ...this.data.attributes[index], id: selectedAttribute.id });
        });
        console.log(this.selectedAttributes)
      },
    },
  },
  async created() {
    await this.fetchCreateData()
  },
  methods: {

    updateSortValidation(index) {
      if (this.selectedAttributes[index] && this.data.attributes[index].sort != null) {
        const otherSorts = this.data.attributes.filter((cat, idx) => idx !== index).map(cat => Number(cat.sort));
        const selectedSort = Number(this.data.attributes[index].sort);


        if (otherSorts.includes(selectedSort)) {
          this.$set(this.sortValidationErrors, index, 'Sort value already exists in the selected attribute');

        } else {
          this.$set(this.sortValidationErrors, index, null); // Clear the error message if no validation error
        }

      }


    },
    addAttribute() {
      this.data.attributes.push(
        {
          id: null,
          sort: null,
          is_include_name: false,
        }
      )

    },

    removeAttribute(index) {
      if (this.data.attributes.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.attributes.splice(index, 1)
      }
    },
    fetchCreateData() {
      axios
        .get('categories/create')
        .then((result) => {

          this.attributes = result.data.data.attributes
          // console.log(result.data.data.attributes)
        })
        .catch((err) => {
          this.$swal({
            position: 'center',
            icon: 'error',
            title: 'Error!',
            showConfirmButton: false,
            timer: 1500,
          })
          this.errors_back.length = 0
          if (err.response.data.data != null) {
            if (this.isString(err.response.data.data)) {
              this.errors_back.push({
                error: err.response.data.data,
              })
            } else {
              this.errors_back = err.response.data.data
            }
            this.showDismissibleAlert = true
          } else {
            this.errors_back = []
            this.errors_back.push({
              error: 'internal server error',
            })
            this.showDismissibleAlert = true
          }
        })

    },
    // submit Category API
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          
          if (this.data.icon.includes(',')) {
            this.data.icon = this.data.icon
              .split(',')
              .map((item) => item.trim())
            this.data.icon = this.data.icon[1]
          }
          if (this.data.cover.includes(',')) {
            this.data.cover = this.data.cover
              .split(',')
              .map((item) => item.trim())
            this.data.cover = this.data.cover[1]
          }
          for (let trn in this.data.translations) {
            this.data.translations[trn].locale = this.tranLocaleSelect[trn].value
          }

          axios
            .post('categories', this.data)
            .then((result) => {
              this.$swal({
                position: 'center',
                icon: 'success',
                title: 'Your work has been saved',
                showConfirmButton: false,
                timer: 1500,
              })
              this.$router.push({
                path: '/Categories',
              })
            })
            .catch((err) => {
              this.$swal({
                position: 'center',
                icon: 'error',
                title: 'Error!',
                showConfirmButton: false,
                timer: 1500,
              })
              this.errors_back.length = 0
              if (err.response.data.data != null) {
                if (this.isString(err.response.data.data)) {
                  this.errors_back.push({
                    error: err.response.data.data,
                  })
                } else {
                  this.errors_back = err.response.data.data
                }
                this.showDismissibleAlert = true
              } else {
                this.errors_back = []
                this.errors_back.push({
                  error: 'internal server error',
                })
                this.showDismissibleAlert = true
              }
            })
        }
      })
    },
    // cencode cover
    uploadImage(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage(files[0])
    },
    createImage(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.data.cover = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // encode icon
    uploadImage2(e) {
      let files = e.target.files || e.dataTransfer.files
      if (!files.length) return
      this.createImage2(files[0])
    },
    createImage2(file) {
      let reader = new FileReader()
      let vm = this
      reader.onload = (e) => {
        vm.data.icon = e.target.result
      }
      reader.readAsDataURL(file)
    },
    // REPETED FORM
    repeateAgain() {
      this.data.translations.push({
        //  id: this.nextTodoId += this.nextTodoId,
      })
      this.tranLocaleSelect.push({})
    },
    // remove function to delete single translation from category
    removeItem(index) {
      if (this.data.translations.length <= 1) {
        this.$bvModal.msgBoxConfirm('You need minmum one tab to continue.', {
          title: 'Alert',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Accept',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
      } else {
        this.data.translations.splice(index, 1)
        this.tranLocaleSelect.splice(index, 1)
      }
    },
    isString(value) {
      return typeof value === 'string' || value instanceof String
    },
    // basic
  },
}
</script>

<style lang="scss"></style>


<style lang="scss" scoped></style>